<form [formGroup]="teamMemberFormGroup()">
  <div class="d-flex w-100">
    <div class="flex-1">
      <mat-label class="required-field">Role</mat-label>
      <mat-form-field class="remove-will-change w-100" appearance="outline">
        <phar-multiple-select
          formControlName="roleTypes"
          placeholder="Select role"
          [data]="roles()"
          [displayValue]="'name'"
          [returnValue]="'id'"
          [filterValue]="'name'" />

        <mat-error> Role is <strong>required</strong> </mat-error>
      </mat-form-field>
    </div>
    <div class="ps-2 d-flex align-items-center">
      <mat-icon
        class="cursor-pointer"
        pharTooltipPosition="above"
        matSuffix
        svgIcon="users"
        pharTooltip="View Roles & Permissions"
        (click)="goToRolesAndPermissions()" />
    </div>
  </div>

  <div class="row">
    <div class="col-4">
      <mat-label class="required-field">First Name</mat-label>
      <mat-form-field class="w-100 remove-will-change">
        <input matInput formControlName="firstName" placeholder="First name" />
        <mat-error>First name is <b>required</b></mat-error>
      </mat-form-field>
    </div>

    <div class="col-4">
      <mat-label>Middle Name</mat-label>
      <mat-form-field class="w-100 remove-will-change">
        <input matInput formControlName="middleName" placeholder="Middle name" />
      </mat-form-field>
    </div>

    <div class="col-4">
      <mat-label class="required-field">Last Name</mat-label>
      <mat-form-field class="w-100 remove-will-change">
        <input matInput formControlName="lastName" placeholder="Last name" />
        <mat-error>Last name is <b>required</b></mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-4">
      <mat-label>Title</mat-label>
      <mat-form-field class="w-100 remove-will-change">
        <input matInput formControlName="title" placeholder="Title" />
      </mat-form-field>
    </div>

    <div class="col-4">
      <mat-label>Degree</mat-label>
      <mat-form-field class="w-100 remove-will-change">
        <input matInput formControlName="degree" placeholder="Degree" />
      </mat-form-field>
    </div>
  </div>

  <div>
    <mat-label class="required-field">Email</mat-label>
    <mat-form-field class="w-100 remove-will-change">
      <input matInput formControlName="email" placeholder="Email" />
      <mat-error>
        @if (teamMemberFormGroup().controls.email.hasError('email')) {
          Please enter a valid email address.
        }
        @if (teamMemberFormGroup().controls.email.hasError('required')) {
          Email address is <b>required.</b>
        }
      </mat-error>
    </mat-form-field>
  </div>

  <div class="row">
    <div class="col-5">
      <mat-label class="required-field">Country Code</mat-label>
      <mat-form-field class="w-100 remove-will-change">
        <phar-autocompleter
          formControlName="countryCode"
          displayValue="dialCode"
          filterValue="dialCode"
          returnValue="dialCode"
          placeholder="Code"
          [data]="dialCodes()"
          [autoCompleter]="true" />
      </mat-form-field>
    </div>
    <div class="col-7">
      <mat-label>Phone number</mat-label>
      <mat-form-field class="w-100 remove-will-change">
        <input matInput formControlName="phoneNumber" placeholder="Phone number" type="number" />
      </mat-form-field>
    </div>
  </div>
</form>
