import { Component, effect, inject, Injector, input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { MatError, MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { PharAutocompleterComponent } from '../../../../shared/phar-dropdown-list-autocompleter/phar-autocompleter.component';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { ICountry } from '../../../../shared/models/country.interface';
import { JsonPipe } from '@angular/common';
import { IRole } from '../../../../shared/models/role.interface';
import { TeamMemberFormGroup } from '../add-edit-team-member/add-edit-team-member.dialog.component';
import { MatRipple } from '@angular/material/core';
import { PharTooltipDirective } from '../../../../shared/directives/tooltip.directive';
import { Router } from '@angular/router';
import { PharMultipleSelectComponent } from '../../../../shared/phar-multiple-select/phar-multiple-select.component';

@Component({
  selector: 'phar-study-team-member-form',
  templateUrl: 'study-team-member-form.component.html',
  styleUrls: ['study-team-member-form.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatIcon,
    MatTooltip,
    MatFormField,
    MatInput,
    MatLabel,
    MatError,
    PharAutocompleterComponent,
    MatOption,
    MatSelect,
    JsonPipe,
    MatSuffix,
    MatRipple,
    PharTooltipDirective,
    PharMultipleSelectComponent,
  ],
})
export class StudyTeamMemberFormComponent {
  teamMemberFormGroup = input.required<FormGroup<TeamMemberFormGroup>>();
  dialCodes = input.required<Pick<ICountry, 'dialCode'>[]>();
  injector: Injector = inject(Injector);
  disabled = input<boolean>(false);
  multipleRoles = input(true);
  router: Router = inject(Router);

  watchIsDisabled = effect(
    () => {
      if (this.disabled()) {
        this.teamMemberFormGroup().disable({ emitEvent: false });
      } else {
        this.teamMemberFormGroup().enable({ emitEvent: false });
      }
    },
    { injector: this.injector },
  );

  roles = input.required<IRole[]>();

  goToRolesAndPermissions(): void {
    this.router.navigate(['dashboard/roles-and-permissions']);
  }
}
